import Address from '@atoms/Address/Address';
import Tags from '@atoms/Tags/Tags';
import { BG_CREME } from '@constants';
import { locationType } from '@lib/proptypes';
import Sections from '@organisms/Sections/Sections';
import TextImageSection from '@organisms/TextImageSection/TextImageSection';
import TextSection from '@organisms/TextSection/TextSection';
import ZorgKaartWidget from '@organisms/ZorgKaartWidget/ZorgKaartWidget';

function LocationDetail({
  page: {
    sections,
    title,
    image,
    address,
    city,
    postal,
    phone,
    isWooninc,
    intro,
    categories,
    widget,
    widgetTitle,
    widgetText,
    widgetButton,
    uspsTitle,
    usps,
  },
}) {
  return (
    <>
      <TextImageSection
        id="location-hero"
        title={title}
        subtitle={isWooninc ? 'WoonincPlusVitalis' : 'Vitalis'}
        text={intro}
        image={image}
        textLeft
        showImageAsBlob
      >
        <Tags tags={categories} />
        <Address
          withIcon
          address={address}
          city={city}
          postal={postal}
          phone={phone}
          message="Goed bereikbaar met fiets en OV."
        />
      </TextImageSection>

      {usps && (
        <TextSection
          id="location-usps"
          title={uspsTitle}
          text={usps}
          backgroundColor={{ color: BG_CREME }}
        />
      )}

      <Sections sections={sections} />

      {widget && (
        <ZorgKaartWidget
          id="location-zorgkaart"
          embedCode={widget}
          title={widgetTitle}
          text={widgetText}
          button={widgetButton}
        />
      )}
    </>
  );
}

LocationDetail.propTypes = {
  page: locationType.isRequired,
};

export default LocationDetail;
