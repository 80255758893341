import getOrCreateCustomerId from '@lib/customer';
import getConfigProps from '@lib/getConfigProps';
import { getBanner, getLocationPage } from '@lib/prepr';
import { locationType } from '@lib/proptypes';
import LocationDetail from '@organisms/LocationDetail/LocationDetail';

function LocationPage({ page }) {
  return <LocationDetail page={page} />;
}

LocationPage.propTypes = {
  page: locationType.isRequired,
};

export async function getServerSideProps(ctx) {
  const { req, params, locale, preview = false } = ctx;

  const customerId = getOrCreateCustomerId(req);
  const { host } = req.headers;
  const { slug } = params;

  const locationPage = getLocationPage({
    host,
    slug: `/${slug}`,
    locale,
    customerId,
    preview,
  });

  const pageBanner = getBanner({ host, locale, customerId, preview });

  const [
    page,
    banner,
    {
      props: { config },
    },
  ] = await Promise.all([locationPage, pageBanner, getConfigProps(ctx)]);

  const hideBanner =
    banner.items.length && ctx?.req?.cookies.hideBanner === banner.items[0].id;

  if (!page) {
    return {
      notFound: true,
    };
  }

  return {
    props: {
      preview,
      page,
      banner,
      hideBanner,
      config,
      host,
      customerId,
    },
  };
}

export default LocationPage;
