import { BG_WHITE } from '@constants';
import { ctaType } from '@lib/proptypes';
import TextMediaWrapper from '@molecules/TextMediaWrapper/TextMediaWrapper';
import Script from 'next/script';
import PropTypes from 'prop-types';

export default function ZorgKaartWidget({
  embedCode,
  title,
  text,
  button,
  id,
}) {
  let src;
  const modifiedHTML = embedCode.replace(
    /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi,
    match => {
      // Extract src attribute from the matched script tag
      const srcMatch = match.match(/src="([^"]*)"/);

      // Log or use the captured href value
      if (srcMatch) {
        // eslint-disable-next-line prefer-destructuring
        src = srcMatch[1];
      }

      // Return an empty string to remove the script tag
      return '';
    }
  );

  return (
    <TextMediaWrapper
      id={id}
      title={title}
      text={text}
      cta={
        button && {
          ...button,
          color: 'secondary',
        }
      }
      textLeft
      backgroundColor={BG_WHITE}
      modifier="zorgkaart"
    >
      <div
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: modifiedHTML }}
      />
      {src && (
        <Script
          id="zorgkaart-widget-script"
          async
          src={src}
          strategy="afterInteractive"
        />
      )}
    </TextMediaWrapper>
  );
}

ZorgKaartWidget.propTypes = {
  id: PropTypes.string,
  embedCode: PropTypes.string.isRequired,
  title: PropTypes.string,
  text: PropTypes.string,
  button: ctaType,
};
